<template>
  <b-row
    class="match-height"
  >
    <b-col
      v-for="(item, index) in AppModules.map(x=>resolveModuleInfo(x.name))"
      :key="index"
      :xl="3"
      :md="6"
    >
      <b-card>
        <h5>{{ item.Title }}</h5>
        <b-card-text class="font-small-3 text-module">
          {{ item.Subtitle }}
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :to="{name: item.Link}"
        >
          {{ item.ButtonText }}
        </b-button>
        <b-img
          :src="require(`@/assets/images/modules/${item.Image}`)"
          class="crm-logo"
          alt="CRM"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg, BRow, BCol,
} from "bootstrap-vue"
// import useAppConfig from '@core/app-config/useAppConfig'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardText,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  setup() {
    // const {
    //   AppModules,
    // } = useAppConfig()
    const AppModules = ref([])
    AppModules.value.push({ name: "preventive" })
    // AppModules.value.push({ name: "customer" })
    // AppModules.value.push({ name: "companies" })
    // AppModules.value.push({ name: "products" })
    return {
      AppModules,
    }
  },
  methods: {
    resolveModuleInfo(modulename) {
      modulename = modulename.toLowerCase()
      if (modulename == 'preventive') {
        return {
          Title: "Preventivi", Subtitle: "Preventivi personalizzati e rapidi in pochi clic.", Image: "insurance_2.png", ButtonText: "View", Link: "estimator-wizard",
        }
      } if (modulename == 'customer') {
        return {
          Title: "Clienti", Subtitle: "Gestione clienti semplificata e organizzata.", Image: "care.png", ButtonText: "View", Link: "agencylist",
        }
      } if (modulename == 'companies') {
        return {
          Title: "Compagnie Assicurative", Subtitle: "Le migliori compagnie assicurative a disposizione.", Image: "policy.png", ButtonText: "View", Link: "companylist",
        }
      } if (modulename == 'products') {
        return {
          Title: "Prodotti Assicurativi", Subtitle: "Gamma completa di prodotti assicurativi di vario genere.", Image: "delivery-insurance.png", ButtonText: "View", Link: "bundlelist",
        }
      }

      return {
        Title: "", Subtitle: "", Image: "", ButtonText: "", Link: "",
      }
    },
  },
}
</script>
<style >
.crm-logo {
    position: absolute;
    top: 16px;
    height: 9em;
    right: 13px;
}
.text-module {
    width: 65%;
}
</style>
