<template>
  <b-card
    v-if="data"
    text-variant="center"
    class="card card-congratulations"
  >
    <!--
    <b-img
      :src="require('@/assets/images/elements/decore-left.png')"
      class="congratulations-img-left"
    />
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
     -->

    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
      :src="require('@/assets/images/modules/ACRISURE_ITALIA_logoshort.jpg')"
    />
    <h1 class="mb-1 mt-50 text-white">
      Benvenuto {{ data.fullName }}!
    </h1>
    <b-card-text class="m-auto w-75">
      Ti affianchiamo nella scelta dei migliori prodotti assicurativi.
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BCardText,
  // BImg
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    // BImg,
    BCardText,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
}
</script>

