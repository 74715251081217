<template>
  <div>
    <analytics />
    <modules />
  </div>
</template>

<script>
import Analytics from "./analytics/Analytics.vue"
import Modules from "./modules.vue"

export default {
  components: {
    Analytics,
    Modules,
  },
}
</script>

<style>

</style>
