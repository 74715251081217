<template>
  <section
    v-if="show"
    id="dashboard-analytics"
  >
    <b-row
      v-for="(group, i) in data"
      :key="i"
      class="match-height"
    >
      <b-col
        lg="12"
        md="12"
      >
        <analytics-welcome :data="userData" />
      </b-col>
      <!--
      <b-col
        v-for="(item, index) in group.sc.scs "
        :key="index"
        :xl="item.data.length*2"
        :md="6"
      >
        <analytics-scs :data="item" />
      </b-col>
      <b-col
        v-for="(item, index) in group.sc.scv "
        :key="index"
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          :icon="item.icon"
          :statistic="item.value"
          :statistic-title="item.title"
          :color="item.color"
        />
      </b-col>
      <b-col
        v-for="(item, index) in group.sc.lc "
        :key="index"
        lg="6"
      >
        <analytics-lc :data="item" />
      </b-col>
      <b-col
        lg="6"
        md="12"
      />
      -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import Vue from 'vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import { getUserData } from '@/auth/utils'
import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'
import {
  ref, watch, computed, onUnmounted, onMounted,
} from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'
import { mapState } from "vuex"
import useAuth from '@/auth/useAuth'
import AnalyticsWelcome from './AnalyticsWelcome.vue'
import AnalyticsLc from './AnalyticsLc.vue'
import AnalyticsScs from './AnalyticsScs.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsScs,
    StatisticCardVertical,
    AnalyticsLc,
    AnalyticsWelcome,
  },
  setup() {
    const data = ref([])
    const show = ref(false)
    const userData = ref({})
    useAuth.fetchAuthUser().then(() => {
      userData.value = useAppConfig().userData.value
      Vue.prototype.$http
        .post('/wsdlr/analytics/companies', { userData: userData.value })
        .then(response => {
          data.value.push(response.data.data)
          show.value = true
        })
    })
    /*
    const { userData } = useAppConfig()
    console.log(`????????????????????userData???????????????????`)
    console.log(userData.value)

    const loadAnalytics = params => {
      Vue.prototype.$http
        .post('/wsdlr/analytics/companies', { params })
        .then(response => {
          data.value.push(response.data.data)
          show.value = true
        })
    }
    loadAnalytics(userData.value)
    // // userData.value = $themeConfig.app.userData
    // // watch(useAppConfig.userData, (currentValue, oldValue) => {
    // // onMounted(() => {
    // watch(userData.value, (currentValue, oldValue) => {
    //   // userData.value = getUserData()
    //   console.log("===USERDATA===")
    //   console.log(currentValue)
    //   Vue.prototype.$http
    //     .post('/wsdlr/analytics/companies', { currentValue })
    //     .then(response => {
    //       data.value.push(response.data.data)
    //       show.value = true
    //     })
    //   //  })
    // })
    */
    // console.log("===USERDATA===")
    // console.log(userData.value)
    // Vue.prototype.$http
    //   .post('/wsdlr/analytics/companies', { userData })
    //   .then(response => {
    //     data.value.push(response.data.data)
    //     show.value = true
    //   })

    return {
      data,
      // loadAnalytics,
      userData,
      show,
    }
  },
  methods: {
    kFormatter,
  },
}
</script>
